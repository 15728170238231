<template>
    <summary-student-info-share />
</template>

<script>
import SummaryStudentInfoShare from "../components/scrollWrapper/SummaryStudentInfoShare";

export default {
    components: { SummaryStudentInfoShare }
}
</script>
