<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-07-29 08:58:17
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-07-29 08:58:17
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\SummarySettingOrganSetting.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
      <!--基础数据 - 组织架构-->
      <KeepAliveRouterView />
    </div>
  </template>
  <script>
  export default {
    name: "SummarySettingOrganSetting"
  }
  </script>