<template>
    <summary-student-info-template />
</template>

<script>
import SummaryStudentInfoTemplate from "../components/scrollWrapper/SummaryStudentInfoTemplate";

export default {
    components: { SummaryStudentInfoTemplate }
}
</script>
