<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-05-16 17:16:32
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-05-16 17:19:29
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\SummaryStudentStatusInfo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <summary-student-status-info />
</template>

<script>
import SummaryStudentStatusInfo from "../components/scrollWrapper/SummaryStudentStatusInfo";

export default {
    components: { SummaryStudentStatusInfo }
}
</script>
