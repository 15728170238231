<template>
    <div>
        <summary-video></summary-video>    
    </div>
</template>

<script>
import SummaryVideo from "../components/scrollWrapper/SummaryVideo"
export default {
  components: { SummaryVideo }
}
</script>

<style scoped>

</style>