<template>
    <div>
        <SummaryTeacherBase
            v-if="show && mode == 'list'"
            :query="searchQuery"
            @change="change"
            @setPageInitTabs="setPageInitTabs"
        ></SummaryTeacherBase>
        <SummaryTeacherInfo
            v-if="mode == 'form'"
            :query="infoParams"
            @change="change"
            @setPageInitTabs="setPageInitTabs"
        />
    </div>
</template>

<script>
import SummaryTeacherBase from "@/components/scrollWrapper/SummaryTeacherBase.vue";
import SummaryTeacherInfo from "@/components/scrollWrapper/SummaryTeacherInfo.vue";
import {mapState} from "vuex";

export default {
    components: {
        SummaryTeacherBase,
        SummaryTeacherInfo,
    },
    props: {
        ruleForm: {
            type: Object,
        },
        rules: {
            type: Object,
        },
        formDataList: {
            type: Array,
        },
        btnList: {
            type: Array,
        },
    },
    data() {
        return {
            mode: "list",
            show: true,
            activeName: "first",
            infoParams: {},
            searchQuery: {},
        };
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
        }),
    },
    created() {
        this.$on('handlerGlobalPageBack', () => {
            console.log('000---000')
            this.handlerGlobalPageBack()
        })
        if (this.$route.query) {
            this.searchQuery.formData = this.$route.query.formData;
            this.searchQuery.listQuery = this.$route.query.listQuery;
        }
    },
    mounted() {
    },
    methods: {
        handlerChangeTabs() {
            console.log("被点击了");
        },
        changeBtn(data) {
            this.$eventBus.$emit("changeBtn", data);
        },
        change(mode, query) {
            this.mode = mode;

            this.setGlobalPageType(mode)
            if (query) {
                this.infoParams = query;
                // 保存查询条件
                if (query.formData) {
                    this.searchQuery.formData = query.formData;
                    this.searchQuery.listQuery = query.listQuery;
                }
                console.log(this.infoParams, "this.infoParams");
            }
        },
        /**
         * @Description: 页面状态 list列表 detail二级页面
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-17 16:51:24
         */
         setGlobalPageType (type) {
            this.$eventDispatch('setGlobalPageType', type)
         },
         handlerGlobalPageBack () {
            this.change('list')
         },
         setPageInitTabs(){
            this.handlerGlobalPageBack()
         }
    },
};
</script>
<style lang="scss" scoped>
.tabs-list {
    display: flex;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #fff;

    .tabs-item {
        width: 100px;
        position: relative;
        text-align: center;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
    }

    .tabs-item.current {
        color: #409eff;
    }

    .line {
        position: absolute;
        bottom: 2px;
        left: 25%;
        width: 50%;
        height: 2px;
        background-color: #409eff;
    }
}
</style>
