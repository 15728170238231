<template>
    <summary-student-base
        v-if="show"
        :query="searchQuery"
    ></summary-student-base>
</template>
<script>
import SummaryStudentBase from "@/components/scrollWrapper/SummaryStudentBase.vue";
import {mapState} from "vuex";
export default {
    components: {
        SummaryStudentBase,
    },
    props: {
        ruleForm: {
            type: Object,
        },
        rules: {
            type: Object,
        },
        formDataList: {
            type: Array,
        },
        btnList: {
            type: Array,
        },
    },
    data() {
        return {
            show: true,
            activeName: "first",
            infoParams: {},
            searchQuery: {},
        };
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
        }),
    },
    created() {
        console.log(this.$route.query, "createdStudent");
        if (this.$route.query) {
            this.searchQuery.formData = this.$route.query.formData;
            this.searchQuery.listQuery = this.$route.query.listQuery;
        }
    },
    mounted() {
        //学生档案去除基础信息的权限
        // this.checkAuth();
    },
    methods: {
        checkAuth() {
            let res = this.permissions.indexOf("studentBase:all");
            if (res == -1) {
                this.show = false;
            }
        },
        handlerChangeTabs() {
            console.log(this.activeName);
        },
        changeBtn(data) {
            this.$eventBus.$emit("changeBtn", data);
        },
    },
};
</script>
<style lang="scss" scoped>
.tabs-list {
    display: flex;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #fff;

    .tabs-item {
        width: 100px;
        position: relative;
        text-align: center;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
    }

    .tabs-item.current {
        color: #409eff;
    }

    .line {
        position: absolute;
        bottom: 2px;
        left: 25%;
        width: 50%;
        height: 2px;
        background-color: #409eff;
    }
}
</style>
