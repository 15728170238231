<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-05-15 15:59:19
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-05-15 16:00:17
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\SummaryArchivesManageStudent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>

        <!-- 学生管理 -->
        <KeepAliveRouterView />

</template>
<script>
export default {
    name: "SummaryArchivesManageStudent"
}
</script>