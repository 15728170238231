<!--
 * @Author: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @Date: 2023-09-27 09:06:27
 * @LastEditors: LAPTOP-FRJGB3M2\10279 1398037353@qq.com
 * @LastEditTime: 2023-09-27 17:38:27
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\SummaryIntroduce.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <summary-introduce ref="summaryIntroduce" @setPageInitTabs="setPageInitTabs" />
</template>

<script>
import SummaryIntroduce from "../components/scrollWrapper/SummaryIntroduce";
export default {
  components: { SummaryIntroduce },
  methods:{
    setPageInitTabs(){
      this.$refs.summaryIntroduce.isEdit = false;
      this.$eventDispatch('setGlobalPageType', 'list');
    }
  }
}
</script>

<style scoped>

</style>
