<template>
    <div>
        <KeepAliveRouterView />
    </div>
</template>
<script>
export default {
    name: "Summary"
}
</script>
