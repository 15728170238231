<template>
    <summary-student-info />
</template>

<script>
import SummaryStudentInfo from "../components/scrollWrapper/SummaryStudentInfo";

export default {
    components: { SummaryStudentInfo }
}
</script>
