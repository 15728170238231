<template>
    <div>
        <SummaryParent></SummaryParent>
    </div>
</template>

<script>
import SummaryParent from "@/components/scrollWrapper/SummaryParent.vue";
export default {
  components: {
    SummaryParent
  },
}
</script>

<style scoped>

</style>